import Metadata from "components/Metadata";
import React from "react";
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Metadata />
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h1>Privacy Policy for Minerva Gate SRL</h1>
          <br />
          <h3>POLICY CONCERNING THE CONFIDENTIALITY OF PERSONAL DATA PROCESSED FROM WITHIN MINERVA GATE</h3>
          <br />
          <h3>INTRODUCTION</h3>
          <br />
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.
          <br />
          <br />
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy.
          <br />
          <br />
          <h3>INTERPRETATION AND DEFINITIONS</h3>
          <br />
          <h3>Interpretation</h3>
          <br />
          The words in which the initial letter is capitalized have meanings defined under the following conditions.
          <br />
          <br />
          The following definitions shall have the same meaning regardless of whether they appear in singular or in the
          plural.
          <br />
          <br />
          <h3>Definitions</h3>
          <br />
          Practically, the Operator and each staff member will have to verify, prior to the processing of certain types
          of personal data as the basis of processing, one of the following legal bases:
          <br />
          <br />
          <b>For the purposes of this Privacy Policy:</b>
          <br />
          <br />
          You means the individual accessing or using the Service, or the company, or other legal entity on behalf of
          which such individual is accessing or using the Service, as applicable.
          <br />
          <br />
          Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as
          you are the individual using the Service.
          <br />
          <br />
          Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Minerva Gate
          SRL, Piatra Neamț, Aleea Trandafirilor 1, Bl. 3, Sc A, Et. 2, Apt 9.
          <br />
          <br />
          <b>For the purpose of the GDPR, the Company is the Data Controller.</b>
          <br />
          <br />
          <ul>
            <li>
              Affiliate means an entity that controls, is controlled by or is under common control with a party, where
              “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to
              vote for the election of directors or other managing authority.
            </li>
            <li>
              Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>Service refers to the Website.</li>
            <li>Country refers to: Romania</li>
          </ul>
          <br />
          Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers
          to third-party companies or individuals employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the Service or to assist the Company in
          analyzing how the Service is used.
          <br />
          <br />
          <b>For the purpose of the GDPR, Service Providers are considered Data Processors.</b>
          <br />
          <br />
          <ul>
            <li>
              Third-party Social Media Service refers to any website or any social network website through which a User
              can log in or create an account to use the Service.
            </li>
            <li>
              LinkedIn Fan Page is a public profile named Minerva Gate specifically created by the Company on the
              LinkedIn social network, accessible from{" "}
              <a href="https://www.linkedin.com/company/minervagate/">https://www.linkedin.com/company/minervagate/</a>
            </li>
            <li>Personal Data is any information that relates to an identified or identifiable individual.</li>
          </ul>
          <br />
          <b>
            For the purposes of the GDPR, Personal Data means any information relating to You such as a name, an
            identification number, location data, online identifier or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural or social identity.
          </b>
          <br />
          <br />
          <b>
            For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or
            is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
          </b>
          <br />
          <br />
          <ul>
            <li>
              Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              Usage Data refers to data collected automatically, either generated by the use of the Service or from the
              Service infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company
              as the legal person which alone or jointly with others determines the purposes and means of the processing
              of Personal Data.
            </li>
            <li>
              Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular, the
              U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for
              allowing internet users to control the tracking of their online activities across websites.
            </li>
            <li>
              Business, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the
              legal entity that collects Consumers’ personal information and determines the purposes and means of the
              processing of Consumers’ personal information, or on behalf of which such information is collected and
              that alone, or jointly with others, determines the purposes and means of the processing of consumers’
              personal information, that does business in the State of California.
            </li>
            <li>
              Consumer, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a
              California resident. A resident, as defined in the law, includes (1) every individual who is in the USA
              for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who
              is outside the USA for a temporary or transitory purpose.
            </li>
            <li>
              Sale, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing,
              disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing,
              or by electronic or other means, a Consumer’s Personal information to another business or a third-party
              for monetary or other valuable consideration.
            </li>
          </ul>
          <br />
          <h3>COLLECTING AND USING YOUR PERSONAL DATA</h3>
          <br />
          <h3>Types of Data Collected</h3>
          <br />
          While using Our Service, We may ask You to provide Us with certain personally identifiable information that
          can be used to contact or identify You. Personally identifiable information may include, but is not limited
          to:
          <br />
          <br />
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
          </ul>
          <br />
          <h3>Use of Your Personal Data</h3>
          <br />
          The Company may use Personal Data for the following purposes:
          <br />
          <br />
          <ul>
            <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
            <li>To contact You: To contact You by email</li>
            <li>To manage Your requests: To attend and manage Your requests to Us.</li>
          </ul>
          <br />
          We may share your personal information in the following situations:
          <br />
          <br />
          <ul>
            <li>
              For Business transfers: We may share or transfer Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our
              business to another company.
            </li>
            <li>
              With Business partners: We may share Your information with Our business partners to offer You certain
              products, services or promotions.
            </li>
          </ul>
          <br />
          <h3>Retention of Your Personal Data</h3>
          <br />
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies.
          <br />
          <br />
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
          <br />
          <br />
          <h3>Transfer of Your Personal Data</h3>
          <br />
          Your information, including Personal Data, is processed at the Company’s operating offices and in any other
          places where the parties involved in the processing are located. It means that this information may be
          transferred to — and maintained on — computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.
          <br />
          <br />
          Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
          to that transfer.
          <br />
          <br />
          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
          or a country unless there are adequate controls in place including the security of Your data and other
          personal information.
          <br />
          <br />
          <h3>Disclosure of Your Personal Data</h3>
          <br />
          <h3>Business Transactions</h3>
          <br />
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
          will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
          Policy.
          <br />
          <br />
          <h3>Law enforcement</h3>
          <br />
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
          by law or in response to valid requests by public authorities (e.g. a court or a government agency).
          <br />
          <br />
          <h3>Other legal requirements</h3>
          <br />
          The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
          <br />
          <br />
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
          <br />
          <h3>Security of Your Personal Data</h3>
          <br />
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
          to protect Your Personal Data, We cannot guarantee its absolute security.
          <br />
          <br />
          <h3>DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL DATA</h3>
          <br />
          <h3>Notification requirement for rectification or deletion of personal data or restriction of processing</h3>
          <br />
          Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
          obligated not to disclose or use it for any other purpose.
          <br />
          <br />
          <h3>Analytics</h3>
          <br />
          We may use third-party Service providers to monitor and analyze the use of our Service.
          <br />
          <br />
          <h3>Google Analytics</h3>
          <br />
          Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google
          uses the data collected to track and monitor the use of our Service. This data is shared with other Google
          services. Google may use the collected data to contextualise and personalise the ads of its own advertising
          network.
          <br />
          <br />
          For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
          <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          <br />
          <br />
          We also encourage you to review Google's policy for safeguarding your data:{" "}
          <a href="https://support.google.com/analytics/answer/6004245">
            https://support.google.com/analytics/answer/6004245
          </a>
          .
          <br />
          <br />
          For more information on what type of information Firebase collects, please visit the Google Privacy Terms web
          page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          <br />
          <br />
          <h3>Hotjar</h3>
          <br />
          Hotjar is a web analytics service offered by Hotjar that tracks and reports website traffic. Hotjar uses the
          data collected to track and monitor the use of our Service. This data is shared with other Hotjar services.
          Hotjar may use the collected data to contextualise and personalise the ads of its own advertising network.
          <br />
          <br />
          For more information on the privacy practices of Hotjar, please visit the Hotjar Privacy Policy web page:{" "}
          <a href="https://www.hotjar.com/legal/policies/privacy/">https://www.hotjar.com/legal/policies/privacy/</a>
          <br />
          <br />
          <h3>GDPR PRIVACY</h3>
          <br />
          <h3>Legal Basis for Processing Personal Data under GDPR</h3>
          <br />
          We may process Personal Data under the following conditions:
          <br />
          <br />
          <ul>
            <li>
              Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
            </li>
            <li>
              Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement
              with You and/or for any pre-contractual obligations thereof.
            </li>
            <li>
              Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which
              the Company is subject.
            </li>
            <li>
              Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of
              another natural person.
            </li>
            <li>
              Public interests: Processing Personal Data is related to a task that is carried out in the public interest
              or in the exercise of official authority vested in the Company.
            </li>
            <li>
              Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests
              pursued by the Company.
            </li>
          </ul>
          <br />
          In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing,
          and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
          requirement necessary to enter into a contract.
          <br />
          <br />
          <h3>Your Rights under the GDPR</h3>
          <br />
          The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise
          Your rights.
          <br />
          <br />
          You have the right under this Privacy Policy, and by law if You are within the EU, to:
          <br />
          <br />
          <ul>
            <li>
              Request access to Your Personal Data. The right to access, update or delete the information We have on
              You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly
              within Your account settings section. If you are unable to perform these actions yourself, please contact
              Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
            </li>
            <li>
              Request correction of the Personal Data that We hold about You. You have the right to have any incomplete
              or inaccurate information We hold about You corrected.
            </li>
            <li>
              Object to processing of Your Personal Data. This right exists where We are relying on legitimate interest
              as the legal basis for Our processing and there is something about Your particular situation, which makes
              You want to object to our processing of Your Personal Data on this ground. You also have the right to
              object where We are processing Your Personal Data for direct marketing purposes.
            </li>
            <li>
              Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when
              there is no good reason for Us to continue processing it.
            </li>
            <li>
              Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen,
              Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right
              only applies to automated information which You initially provided consent for Us to use or where We used
              the information to perform a contract with You.
            </li>
            <li>
              Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You
              withdraw Your consent, We may not be able to provide You with access to certain specific functionalities
              of the Service.
            </li>
          </ul>
          <br />
          <h3>Exercising of Your GDPR Data Protection Rights</h3>
          <br />
          You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please
          note that we may ask You to verify Your identity before responding to such requests. If You make a request, We
          will try our best to respond to You as soon as possible.
          <br />
          <br />
          You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal
          Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data
          protection authority in the EEA.
          <br />
          <br />
          <h3>LINKEDIN FAN PAGE</h3>
          <br />
          <h3>Data Controller for the LinkedIn Fan Page</h3>
          <br />
          The Company is the Data Controller of Your Personal Data collected while using the Service. As an operator of
          the LinkedIn Fan Page (
          <a href="https://www.linkedin.com/company/minervagate">https://www.linkedin.com/company/minervagate</a>) the
          Company and the operator of the social network LinkedIn are Joint Controllers.
          <br />
          <br />
          The Company has entered into agreements with LinkedIn that define the terms for use of the LinkedIn Fan Page,
          among other things. These terms are mostly based on the LinkedIn Terms of Service:&nbsp;
          <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
          <br />
          <br />
          Visit the LinkedIn Privacy Policy https://www.linkedin.com/legal/privacy-policy for more information about how
          LinkedIn manages Personal data or contact LinkedIn online, or by mail:{" "}
          <b>1000 West Maude Avenue Sunnyvale, CA 94085 USA</b>.
          <br />
          <br />
          <h3>LinkedIn Insights</h3>
          <br />
          We use the LinkedIn Insights function in connection with the operation of the LinkedIn Fan Page and on the
          basis of the GDPR, in order to obtain anonymized statistical data about Our users.
          <br />
          <br />
          For this purpose, LinkedIn places a Cookie on the device of the user visiting Our LinkedIn Fan Page. Each
          Cookie contains a unique identifier code and remains active for a period of two years, except when it is
          deleted before the end of this period.
          <br />
          <br />
          LinkedIn receives, records and processes the information stored in the Cookie, especially when the user visits
          the LinkedIn services, services that are provided by other members of the LinkedIn Fan Page and services by
          other companies that use LinkedIn services.
          <br />
          <br />
          For more information on the privacy practices of LinkedIn, please visit LinkedIn Privacy Policy here:{" "}
          <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
          <br />
          <br />
          <h3>CCPA PRIVACY</h3>
          <br />
          <h3>Your Rights under the CCPA</h3>
          Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:
          <br />
          <br />
          <ul>
            <li>
              The right to notice. You must be properly notified of which categories of Personal Data are being
              collected and the purposes for which the Personal Data is being used.
            </li>
            <li>
              The right to access / the right to request. The CCPA permits You to request and obtain from the Company
              information regarding the disclosure of Your Personal Data that has been collected in the past 12 months
              by the Company or its subsidiaries to a third-party for the third-party’s direct marketing purposes.
            </li>
            <li>
              The right to say no to the sale of Personal Data. You also have the right to ask the Company not to sell
              Your Personal Data to third parties. You can submit such a request by visiting our “Do Not Sell My
              Personal Information” section or web page.
            </li>
          </ul>
          <br />
          <br />
          The right to know about Your Personal Data. You have the right to request and obtain from the Company
          information regarding the disclosure of the following:
          <ul>
            <li>The categories of Personal Data collected</li>
            <li>The sources from which the Personal Data was collected</li>
            <li>The business or commercial purpose for collecting or selling the Personal Data</li>
            <li>Categories of third parties with whom We share Personal Data</li>
            <li>The specific pieces of Personal Data we collected about You</li>
            <li>
              The right to delete Personal Data. You also have the right to request the deletion of Your Personal Data
              that has been collected in the past 12 months.
            </li>
          </ul>
          <br />
          <br />
          The right not to be discriminated against. You have the right not to be discriminated against for exercising
          any of Your Consumer’s rights, including by:
          <ul>
            <li>Denying goods or services to You</li>
            <li>
              Charging different prices or rates for goods or services, including the use of discounts or other benefits
              or imposing penalties
            </li>
            <li>Providing a different level or quality of goods or services to You</li>
            <li>
              Suggesting that You will receive a different price or rate for goods or services or a different level or
              quality of goods or services
            </li>
          </ul>
          <br />
          <br />
          <h3>Exercising Your CCPA Data Protection Rights</h3>
          <br />
          In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or
          call us or visit our “Do Not Sell My Personal Information” section or web page.
          <br />
          <br />
          The Company will disclose and deliver the required information free of charge within 45 days of receiving Your
          verifiable request. The time period to provide the required information may be extended once by an additional
          45 days when reasonably necessary and with prior notice.
          <br />
          <br />
          <h3>Do Not Sell My Personal Information</h3>
          <br />
          We do not sell personal information. However, the Service Providers we partner with (for example, our
          advertising partners) may use technology on the Service that “sells” personal information as defined by the
          CCPA law.
          <br />
          <br />
          If you wish to opt out of the use of your personal information for interest-based advertising purposes and
          these potential sales as defined under CCPA law, you may do so by following the instructions below.
          <br />
          <br />
          Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that
          you use.
          <br />
          <br />
          <h3>Website</h3>
          <br />
          You can opt out of receiving ads that are personalized as served by our Service Providers by following our
          instructions presented on the Service:
          <ul>
            <li>From Our “Cookie Consent” notice banner</li>
            <li>Or from Our “CCPA Opt-out” notice banner</li>
            <li>Or from Our “Do Not Sell My Personal Information” notice banner</li>
            <li>Or from Our “Do Not Sell My Personal Information” link</li>
          </ul>
          <br />
          <br />
          The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you
          change browsers or delete the cookies saved by your browser, you will need to opt out again.
          <br />
          <br />
          <h3>Mobile Devices</h3>
          <br />
          Your mobile device may give you the ability to opt out of the use of information about the apps you use in
          order to serve you ads that are targeted to your interests:
          <br />
          <ul>
            <li>“Opt out of Interest-Based Ads” or “Opt out of Ads Personalization” on Android devices</li>
            <li>“Limit Ad Tracking” on iOS devices</li>
          </ul>
          <br />
          <br />
          You can also stop the collection of location information from Your mobile device by changing the preferences
          on your mobile device.
          <br />
          <br />
          <b>“DO NOT TRACK” POLICY AS REQUIRED BY CALIFORNIA ONLINE PRIVACY PROTECTION ACT (CalOPPA)</b>
          <br />
          <br />
          <b>Our Service does not respond to Do Not Track signals.</b>
          <br />
          <br />
          However, some third-party websites do keep track of You browsing activities. If You are visiting such
          websites, You can set Your preferences in Your web browser to inform websites that You do not want to be
          tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
          <br />
          <br />
          <h3>CHILDREN’S PRIVACY</h3>
          <br />
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
          has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
          from anyone under the age of 13 without verification of parental consent, We take steps to remove that
          information from Our servers.
          <br />
          <br />
          We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old.
          In some cases, this means We will be unable to provide certain functionality of the Service to these users.
          <br />
          <br />
          If We need to rely on consent as a legal basis for processing Your information and Your country requires
          consent from a parent, We may require Your parent’s consent before We collect and use that information.
          <br />
          <br />
          <b>YOUR CALIFORNIA PRIVACY RIGHTS (CALIFORNIA BUSINESS AND PROFESSIONS CODE SECTION 22581)</b>
          <br />
          <br />
          California Business and Professions Code section 22581 allows California residents under the age of 18 who are
          registered users of online sites, services or applications to request and obtain removal of content or
          information they have publicly posted.
          <br />
          <br />
          To request removal of such data, and if you are a California resident, You can contact Us using the contact
          information provided below and include the email address associated with Your account.
          <br />
          <br />
          Be aware that Your request does not guarantee complete or comprehensive removal of content or information
          posted online and that the law may not permit or require removal in certain circumstances.
          <br />
          <br />
          <h3>LINKS TO OTHER WEBSITES</h3>
          <br />
          Our Service may contain links to other websites that are not operated by Us. If You click on a third-party
          link, You will be directed to that third-party’s site. We strongly advise You to review the Privacy Policy of
          every site You visit.
          <br />
          <br />
          We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third-party sites or services.
          <br />
          <br />
          <h3>CHANGES TO THIS PRIVACY POLICY</h3>
          <br />
          We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
          Privacy Policy on this page.
          <br />
          <br />
          We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the “Last updated” date at the top of this Privacy Policy.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
          <br />
          <br />
          <h3>CONTACT US</h3>
          If you have any questions about this Privacy Policy, You can contact us:
          <br />
          <ul>
            <li>By email: contact@minervagate.com</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
